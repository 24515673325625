<template>
    <div
        class="plans__pro"
        :class="isMoneyMakerPlan ? 'plans--active' : 'plans--inactive'"
    >
        <div>
            <div
                class="plans__title"
            >
                Money-maker
            </div>
            <ul class="card-body">
                <li>
                    <p :class="{'white': isMoneyMakerPlan}">
                        Unlimited friends
                    </p>
                    <Check
                        v-if="isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <p :class="{'white': isMoneyMakerPlan}">
                        <span class="title-percent">25%</span> on every purchase
                    </p>
                    <Check
                        v-if="isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <div>
                        Spend money on our platform
                    </div>
                    <Check
                        v-if="!isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <div>
                        Cash withdrawal<br>
                        <div class="payment-options">
                            <span class="payment-options__item">PayPal</span>
                            <span class="payment-options__item">Bank Transfer</span>
                            <span class="payment-options__item">Payoneer</span>
                            <span class="payment-options__item">Stripe</span>
                            <span class="payment-options__item">Wise</span>
                            <span class="payment-options__item">USDT</span>
                        </div>
                    </div>
                    <Check
                        v-if="!isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <div>
                        Personal account manager
                    </div>
                    <Check
                        v-if="!isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
            </ul>
        </div>
        <div
            :class="!isMoneyMakerPlan ? 'card-footer' : 'card-footer-currently-set--money-maker'"
        >
            <custom-button
                v-if="!isMoneyMakerPlan"
                class="btn-base btn-main button"
                @on-btn-click="startMoneyMaker()"
            >
                Apply
            </custom-button>
            <custom-button
                v-else
                class="btn-base btn-main button"
            >
                your current plan
            </custom-button>
        </div>
    </div>
</template>

<script>
import Check from 'mdi-vue/Check.vue';

export default {
    name: 'AffiliatePlansMoneyMaker',
    components: {
        Check
    },
    props: {
        isMoneyMakerPlan: {
            type: Object,
            required: true
        }
    },
    methods: {
        startMoneyMaker() {
            this.$router.push({ name: 'affiliate-signup' })
        }
    }
}

</script>
