<template>
    <div>
        <div class="invite_widget">
            <div class="invite_widget--head">
                <!--                <p class="invite-heading">-->
                <!--                    <span class="invite-heading__title">Affiliate Status Bar</span>-->
                <!--                </p>-->
                <!--                <span class="invite_widget&#45;&#45;label" />-->
            </div>
            <div>
                <div class="invite_methods">
                    <friends-top-bar
                        :loading="false"
                        :counters="statistics"
                    />
                    <router-link
                        :to="{name: 'friends'}"
                        class="btn-base btn-main page-footer__row-top__right-ask"
                    >
                        view details
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import ChevronUpIcon from 'mdi-vue/ChevronUp'
// import ChevronDownIcon from 'mdi-vue/ChevronDown'

import FriendsTopBar from './FriendsTopBar.vue'

export default {
    name: 'StatusWidget',
    components: {
        FriendsTopBar
        // ChevronUpIcon,
        // ChevronDownIcon
    },
    props: {
        statistics: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showArrow: true,
            isWidgetOpen: 1,
            counters: {
                total_earnings_friends: 2
            }
        }
    },
    async created() {
        if (localStorage.closedInviteWidget) {
            this.isWidgetOpen = await this.getWidgetChevronState()
        }
    },
    methods: {
        async getWidgetChevronState() {
            return Number(await localStorage.getItem('closedInviteWidget'))
        },
        setWidgetChevronState(state) {
            localStorage.setItem('closedInviteWidget', state)
        },
        async slideWidget() {
            this.isWidgetOpen = Number(!this.isWidgetOpen)
            this.setWidgetChevronState(this.isWidgetOpen)
        }
    }
}
</script>
