<template>
    <div class="section">
        <slot />
        <div
            v-for="(question, index) in faqFriends"
            :key="index"
            class="friends_info-wrapper"
        >
            <div
                class="friends_info-title-affiliate"
                @click="showDescription(index)"
            >
                <span
                    :class="{ 'friends_info-title--active-affiliate' : isDescriptionOpen === index }"
                >
                    {{ question.title }}
                </span>
                <span class="friends_info-chevron">
                    <chevron-up-icon
                        v-if="isDescriptionOpen === index"
                        class="friends_info-chevron&#45;&#45;active"
                    />
                    <chevron-down-icon v-else />
                </span>
            </div>
            <transition name="slide">
                <span
                    v-show="isDescriptionOpen === index"
                    class="friends_info-description-affiliate"
                >
                    {{ question.description }}
                </span>
            </transition>
        </div>
    </div>
</template>

<script>

import ChevronUpIcon from 'mdi-vue/ChevronUp'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

export default {
    name: 'AffiliateFaq',
    components: {
        ChevronUpIcon,
        ChevronDownIcon
    },
    data() {
        return {
            isDescriptionOpen: null
        }
    },
    computed: {
        faqFriends() {
            return [
                {
                    title: 'How does this program work?',
                    description: `As a referrer, you will get USD $25.00 on your account for every friend you refer to us.
                        The friend must make an actual purchase
                        on our site in order for you to receive your reward.
                        It’s a good way to help someone and make virtual money to pay for your assignments. As the user of this platform,
                        you will receive both the "SignUp Reward" and
                        the "Purchase Reward".`
                },
                {
                    title: 'How do I invite friends?',
                    description: `Copy your referral link and share it in messengers or on social media. You can also e-mail the link directly to your friend.
                    The more friends you bring to our service, the more funds you generate in your account!`
                },
                {
                    title: 'What is the "SignUp Reward"?',
                    description: `The "SignUp Reward" is USD $25. It is generated every time your friend becomes our user by registering and placing their first
                    successful order.`
                },
                {
                    title: 'What is the "Purchase Reward"?',
                    description: `The "Purchase Reward" is currently set at 5% of each order amount. Every time your friends make an order with us,
                    your account receives a "Purchase Reward".`
                },
                {
                    title: 'When will I get my "SignUp" Reward?',
                    description: 'Once we confirm your referral completed their first order, you’ll get a top-up to your balance. You will see all the statistics in your account.'
                },
                {
                    title: 'Do I get rewarded?',
                    description: 'Our system will automatically credit your account with 5% of each purchase amount, every time, for all of your friends. That is the Purchase Reward.'
                }
            ]
        }
    },
    methods: {
        showDescription(index) {
            this.isDescriptionOpen === index
                ? this.isDescriptionOpen = false
                : this.isDescriptionOpen = index
        }
    }
}

</script>

<style lang="scss">
.friends_info-title-affiliate {
    font-size: 16px;
    font-weight: normal;
    user-select: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.friends_info-title--active-affiliate {
    font-size: 16px;
    user-select: none;
}

.friends_info-description-affiliate{
    max-width: 760px;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    color: #1f2939;
    font-weight: 300;
    word-break: normal;
    margin: 10px 0;
    display: flex;
}
</style>
