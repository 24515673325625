<template>
    <div
        class="plans__base"
        :class="isMoneyMakerPlan ? 'plans--inactive' : 'plans--active'"
    >
        <div>
            <div class="plans__title">
                Regular
            </div>
            <ul class="card-body">
                <li>
                    <p :class="{'white': isMoneyMakerPlan}">
                        Unlimited friends
                    </p>
                    <Check
                        v-if="isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <p :class="{'white': isMoneyMakerPlan}">
                        <span class="title-percent">5%</span> on every purchase
                    </p>
                    <Check
                        v-if="isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
                <li>
                    <p>
                        Spend money on our platform
                    </p>
                    <Check
                        v-if="isMoneyMakerPlan"
                        height="35px"
                        width="35px"
                        class="white"
                    />
                    <Check
                        v-else
                        height="35px"
                        width="35px"
                    />
                </li>
            </ul>
        </div>
        <div
            :class="{'card-footer-currently-set': !isMoneyMakerPlan}"
        >
            <custom-button
                v-if="!isMoneyMakerPlan"
                class="btn-base btn-main button"
            >
                your current plan
            </custom-button>
        </div>
    </div>
</template>

<script>
import Check from 'mdi-vue/Check.vue';

export default {
    name: 'AffiliatePlansRegular',
    components: {
        Check
    },
    props: {
        isMoneyMakerPlan: {
            type: Object,
            required: true
        }
    }
}

</script>
